@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services {
    width: 138%;
  }
  #header-intro {
    background-position: 50% 35%;
  }
  #testimonials,
  #team,
  #contact{
    width: 135%;
  }
  #footer {
    width: 111%;
  }

  #portfolio {
    margin: 17px;
    width: 135%;
  }
}
